@import "./../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "react-toastify/dist/ReactToastify.css";
@import "responsiveness.scss";
@import "fancy-navbar.scss";
// @import 'react-photoswipe/dist/photoswipe.css'; // moved it into component

:root {
    --fs-xs: 0.75rem;
    --fs-sm: 0.813rem;
    --fs-md: 1rem;
    --fs-lg: 1.25rem;
    --fs-xl: 1.5rem;
    --default-border-radius: 4px;
}

.light-theme {
    --base-color: #f1f5f9; // #eef4fc;
    --base-light: #f8f8f8; // white-light
    --border-color: #cbd5e1;
    --hero-bg: #213b52;

    --base-invert: #000b2b;
    --bg-max: #ffffff;
    --bg-light: #e3e0ea;
    --text-color: #000000;
	--text-invert: #ffffff;
    --text-dim: #9a9ab5; // same
    --backdrop: #ffffff80;

    --border-primary: #dcd2f9;
    --border-default: #ababab;

    --primary-purple: #8e69ee; //546ae4

    --nav-icon-bg: #9a9ab5; // same
    --nav-text-color: #9a9ab5;

    --green-dark: #36c4a3;
    --green-light: #edf9f6;
    --orange-dark: #f1a12c;
    --orange-light: #fff2e7;
    --purple-dark: #d043ff;
    --purple-light: #f8e2ff;
    --red-dark: #ff6b69;
    --red-light: #ffe5e5;
    --blue-dark: #546ae4;
    --blue-light: #e0e4fb;
    --modal-backdrop: #e0e4fb3d;
    --shadow-color: #d1d1d5;

    // box-shadow: 0px 1px 3px 0px #e1e1e1;
    // --shadow-color: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);

    --box-shadow: 0px 0px 5px 0px #f1f1f1;
    --hover-shadow: 0px 0px 10px 1px #eaeaea;
    --transition-all-02s: all 0.2s ease-in-out;
    --color-filter: brightness(0);
    transition: all 0.2s ease-in-out;
}

.dark-theme {
    --base-color: #000b2b; //navy-blue
    --base-light: #010e34; // navy-light
    --border-color: #585858; // #2c1e35;
    --hero-bg: #213b52;

    --base-invert: #f3f3f3;
    --bg-max: #000000;
    --bg-light: #ffffff2b;
    --text-color: #ffffff;
    --text-invert: #000000;
    --text-dim: #9a9ab5; // same
    --backdrop: #00000080;

    --border-primary: #66619b;
    --border-default: #4b5177;

    --primary-purple: #8e69ee; //546ae4
    --nav-icon-bg: #9a9ab5; // same
    --nav-text-color: #9a9ab5;

    --green-dark: #1c9478;
    --green-light: #1b201f;
    --orange-dark: #f1a12c;
    --orange-light: #2f261d;
    --purple-dark: #d043ff;
    --purple-light: #2c1e35;
    --red-dark: #ff6b69;
    --red-light: #3c2323;
    --blue-dark: #546ae4;
    --blue-light: #23273a;

    --shadow-color: #3b3b3b;
    --modal-backdrop: #23273a40;
    --box-shadow: 0px 0px 5px 0px #101010;
    --hover-shadow: 0px 0px 10px 1px #23273a;
    --transition-all-02s: all 0.2s ease-in-out;
    --color-filter: brightness(1);

    transition: all 0.2s ease-in-out;
}

// body {
//     margin: 0;
//     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
//         "Droid Sans", "Helvetica Neue", sans-serif;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;

    font-size: 16px;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    // text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    color: var(--text-color);
    background-color: var(--base-color);
    // transition: var(--transition-all-02s);
    // position: relative;
    // min-height: 100vh !important;
}

.body-text-color {
    color: var(--text-color);
}

a {
    text-decoration: none;
    color: var(--text-color);
    &:hover {
        text-decoration: none;
        color: var(--text-color);
    }
}

.primary-text {
    color: var(--primary-purple);
}

.pointer {
    cursor: pointer;
}

.success-color {
    color: var(--green-dark) !important;
}

.lazy-loader {
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.section-loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
    
    // margin: 15px;
    // background: var(--base-color);
    transform: translate(-50%, -50%);

    img {
      max-width: 50px;
    }
}

.button-loader {
    backdrop-filter: blur(4px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: var(--border-color);
    // border-radius: 4px;
    z-index: 10;
    img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        filter: brightness(2);
    }
}

.page-loader {
    position: relative;
    width: 100% !important;
    // min-width: inherit;
    min-height: 100vh;
    img {
        position: absolute;
        left: 50%;
        // top: 50%;
        transform: translate(-50%, -50%);
    }
}

.action-item {
    .square-btn {
        outline: 0;
        border: 0;
        cursor: pointer;
        border-radius: 4px;
        transition: all 0.2s ease;
        // transition: var(--transition-all-02s);
        height: 2.5rem;
        // width: 2.5rem;
    }
    .toggle-theme {
        width: 2.5rem;
        background-color: var(--base-invert);
    }
    .toggle-language {
        width: 2.5rem;
        background-color: transparent;
        color: var(--text-color);
        border: 1px solid var(--primary-purple);
        img {
            width: 100%;
            height: 100%;
            // object-fit: cover;
        }
    }
    // .report {
    //     background-color: var(--primary-purple);
    // }
    // .filter {
    //     background-color: var(--bg);
    //     box-shadow: var(--box-shadow);
    //     .icon {
    //         img {
    //             filter: var(--color-filter);
    //             transition: var(--transition-all-02s);
    //         }
    //     }
    // }
}

.imparo-btn {
    position: relative;
    outline: 0;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 15px;
    color: var(--text-color);
    font-size: var(--fs-md);
    vertical-align: text-bottom;
    user-select: none;
    background-color: transparent;
    // height: 2.5rem;
    // display:  block;
    transition: 0.2s all ease-in-out;
    &.primary-button {
        border: 1px solid var(--primary-purple);
        background: var(--bg-max);
    }
    &.danger-button {
        border: 1px solid var(--red-dark);
        color: var(--red-dark);
    }
    &.info-button {
        border: 1px solid var(--blue-dark);
        color: var(--blue-dark);
    }
    &.purple-button {
        background-color: var(--primary-purple);
        color: var(--purple-light);
    }
    &.orange-button {
        background-color: var(--orange-dark);
        color: var(--orange-light);
    }
    &.secondary-button {
        border: 1px solid var(--border-color);
        color: 1px solid var(--text-dim);
    }
    &.light-purple-button {
        background-color: var(--purple-light);
        color: var(--primary-purple);
        border: 1px solid var(--primary-purple);
    }
    // &.green-button {
    //     background-color: var(--green-dark);
    // }
    // &.blue-button {
    //     background-color: var(--blue-dark);
    // }
    &.red-button {
        background-color: var(--red-light);
        color: var(--red-dark);
    }
    &.btn-small {
        padding: 0px 5px !important;
        font-size: 14px;
    }
    &.upload-button {
        background-color: #ecedf0;
        color: #707070;
        border: 2px solid #b8b8b8;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            // filter: invert(47%) sepia(10%) saturate(4%) hue-rotate(18deg) brightness(89%) contrast(82%);
        }
        // &:hover{
        //     color: var(--text-color);
        //     background-color: transparent;
        //     img{
        //         filter: invert(0%) sepia(91%) saturate(7465%) hue-rotate(21deg) brightness(96%) contrast(103%);
        //     }
        // }
    }
    // &.rounded {
    //     border-radius: 30px !important;
    // }
    // &.blue-border-btn {
    //     color: var(--blue-dark);
    //     background-color: transparent;
    //     border: 1px solid var(--blue-dark);
    // }
    // &.green-border-btn {
    //     color: var(--green-dark);
    //     background-color: transparent;
    //     border: 1px solid var(--green-dark);
    // }
    &:hover {
        filter: brightness(0.85);
        // color: brightness(1);
    }
    &:disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }
    img {
        width: 25px;
        // filter: invert(0%) sepia(91%) saturate(7465%) hue-rotate(21deg) brightness(96%) contrast(103%);
    }
}

.btn-thin {
    padding: 3px 15px !important;
}

.imparo-input-field {
    border-color: var(--border-color);
    // background-color: var(--bg-max);
    background-color: transparent;
    border-radius: 5px;
    min-height: 2.5rem;
    padding-left: 10px;
    color: var(--text-color);
    // border-left: var(--default-border-radius) solid var(--primary-purple);
    &:focus {
        color: var(--text-color);
        background-color: var(--bg-max);
        // border-left: var(--default-border-radius) solid var(--primary-purple) !important;
        border-color: var(--purple-light);
        outline: 0;
        //   box-shadow: 0 0 0 0.2rem #8d7ac2;
        box-shadow: 0 0 0 0.2rem #8e69ee52;
    }
    &::placeholder {
        color: var(--text-dim);
        opacity: 0.75;
    }
    &:disabled {
        // opacity: 0.75;
        cursor: not-allowed;
        background-color: var(--base-light);
    }
    &.purple-input {
        background-color: var(--purple-light);
        &::placeholder {
            color: var(--primary-purple);
        }
    }
    &.input-filter {
        border: none;
        border-bottom: 1px solid var(--purple-light);
        &:focus {
            box-shadow: none;
            background-color: transparent;
            border-left: none !important;
            border-bottom-color: var(--primary-purple);
        }
    }
}

input[type="radio"],
input[type="checkbox"] {
    box-shadow: none !important;
    border-color: var(--border-default) !important;
    &:checked {
        border-color: var(--primary-purple) !important ;
        background-color: var(--primary-purple) !important;
    }
}
input[type="select"] {
    color: red !important;
}


input.switch {
    position: relative;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 35px !important;
    height: 20px;
    border-radius: 30px;
    cursor: pointer;
    background: var(--border-color);
    transition: box-shadow 0.3s ease-in-out, padding 0.25s ease-in-out;
    transition-delay: 0.1s, 0s;
    &:focus {
        border: 0;
        outline: 0;
    }
    &:checked {
        transition-delay: 0s, 0s;
        // box-shadow: inset 0 0 0 2.62857em #00c169 !important;
        background-color: var(--blue-dark);
        padding-left: 15px;
    }
    &:before {
        display: inline-block;
        height: 14px;
        width: 14px;
        margin: 3px;
        background-color: #fff;
        border-radius: 2.1em;
        transition: all 0.1s ease 0.1s;
        content: "";
        display: block;
    }
    &:after {
        content: "";
        display: block;
    }
    &:active {
        &::before {
            transition: all 0.1s ease 0.05s;
        }
    }
}

// .imparo-check-box{
//     border-color: var(--border-default) !important;
//     &:checked{
//         background-color: var(--primary-purple);
//         border-color: var(--primary-purple) !important;
//     }
//     &:focus{
//         box-shadow: none;
//     }
// }

.dd-button {
	background: none;
    border: none;
    outline: none;
}
.MuiMenuItem-root {
	min-width: 150px !important; 
	a {
		// color: var(--text-invert) !important;
	}
}

.section-meta {
    position: relative;
    .heading {
        font-size: var(--fs-xl);
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid var(--border-default);
            margin-bottom: -15px;
        }
        &.colored {
            color: var(--primary-purple);
        }
    }
}

.seperator {
    border-color: var(--border-default);
    margin-top: 0;
    margin-bottom: 0;
}

.modal-content {
    background-color: var(--bg-max);
    box-shadow: 0px 5px 15px var(--shadow-color);
    .modal-header, .modal-footer {
        border-color: var(--border-color);
    }

}
.it-modal {
    font-size: var(--fs-md);
    backdrop-filter: blur(2px);
    background-color: var(--modal-backdrop);
    .modal-content {
        background-color: var(--bg-max);
        box-shadow: 0px 5px 15px var(--shadow-color);
        .modal-footer,
        .modal-header {
            border-bottom: 0px;
            border-top: 0px;
        }
    }
    .close-modal {
        border: 2px solid var(--text-color);
        background-color: transparent;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        color: var(--text-color);
    }
    label {
        font-size: var(--fs-sm);
    }
    .imparo-input-field {
        min-height: 1.75rem;
        padding: 0.25rem 0.75rem !important;
    }
}

.input-file-wrapper {
    position: relative;
    max-width: 175px;
    .btn-upload {
        border: 1px solid var(--primary-purple);
        background-color: var(--purple-light);
        padding: 5px 40px;
        border-radius: 5px;
        font-size: 13px;
        width: 100%;
        color: var(--text-color);
        // cursor: pointer;
    }
    input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 175px !important;
        height: 100%;
        // cursor: pointer;
    }
}

// .modal-backdrop {
//     z-index: 100 ;
// }
// .imparo-modal{
//     .modal-content{
//         background-color: var(--bg-max);
//         &.large-width{
//             min-width: 60vw !important;
//         }
//         .imparo-modal-header{
//             border: none !important;
//             .imparo-modal-title{
//                 font-weight: 800;
//                 font-size: var(--fs-lg);
//                 color: var(--text-color);
//             }
//         }
//         .sub{
//             color: var(--text-color);
//             text-align: center;
//         }
//         .imparo-modal-footer{
//             border: none !important;
//         }
//     }
// }

.imparo-stepper {
    .MuiSvgIcon-root.Mui-completed {
        color: var(--primary-purple);
    }
    // .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    //     color: var(--purple-light);
    //     border: 1px solid var(--primary-purple);
    //     border-radius: 50%;
    //     .css-117w1su-MuiStepIcon-text{
    //         fill: var(--primary-purple);
    //     }
    // }
    .MuiSvgIcon-root.Mui-active {
        color: transparent;
        border: 1px solid var(--primary-purple);
        border-radius: 50%;
        .MuiStepIcon-text {
            fill: var(--primary-purple);
        }
    }
    .MuiStepLabel-label {
        color: var(--text-color);
    }
    .MuiStepLabel-label.Mui-active {
        color: var(--text-color);
    }
    .MuiStepLabel-label.Mui-completed {
        color: var(--text-color);
    }
    // .css-1sn4lm3-MuiTypography-root {
    //     color: var(--text-color);
    // }
    .MuiSvgIcon-root {
        color: var(--border-default);
    }
}

.imparo-scrollbar::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
    background-color: var(--bg-light);
    border-radius: 10px;
}

.imparo-scrollbar::-webkit-scrollbar {
    width: 5px;
    background-color: var(--bg-light);
}

.imparo-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    transition: var(--transition-all-02s);
    background-image: -webkit-gradient(
        linear,
        40% 0%,
        75% 84%,
        from(var(--bg-light)),
        to(var(--bg-light)),
        color-stop(0.6, var(--bg-light))
    );
}
.underline {
    border-bottom: 1px solid var(--text-color);
}

.imparo-tabs {
    .tab-item {
        // border-radius: 10px;
        color: var(--text-dim);
        // color: var(--small-button);
        text-transform: capitalize;
        font-size: 1rem;
        cursor: pointer;
        margin-right: 40px;
        border-bottom: 2px solid transparent;
        padding-bottom: 5px;
        &:hover {
            filter: var(--hover-brightness);
        }
        &.active {
            // filter: var(--active-brightness);
            color: var(--text-color) !important;
            border-color: var(--primary-purple);
        }
    }
}
