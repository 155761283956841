/*--------------------------------------------------------------
# Responsiveness
--------------------------------------------------------------*/
@media (min-width: 1199px) and (max-width: 1500px) {
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (max-width: 767px) {
    .hero{
        .hero-content {
            right: 15px;
            left: 15px;
            transform: translateY(-70%) !important;
        }
    }
    .admin-representatives {
        .detail-area {
            &.expanded {
                width: 100% !important;
                // width: ;
            }
        }
    }
}

@media (max-width: 450px) {
}
